<template>
  <v-main
    class="fill-height ml-4 mr-4 mt-4 mb-4"
    fluid
    style="margin: auto !important; padding: 0px; width: 90%"

    v-if="loaded"
  >

    <v-row>
        <v-col>
          <v-card class="mx-auto">
         <v-list-item-content>
    
        <v-list-item-title class="headline mb-1 ml-4">
          <v-row>
               <v-col class="overline mb-2 align-left">
               {{ siteData.uri }} <v-btn class="ml-4" :color="(siteData.environment == 'Live') ? 'green' : 'grey'">{{ siteData.environment }}</v-btn>
              </v-col>
              <v-col class="text-right mr-4">
                  <v-icon
                  color="red"
                  @click="deleteDialog = true"
                >
                    mdi-delete
                </v-icon>
                </v-col>
            </v-row>
        </v-list-item-title>
      
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
             
                <th class="text-left">
                  Wordpress User
                </th>
                <th class="text-left">
                  Wordpress Password
                </th>
                <th class="text-left">
                  SFTP User
                </th>
                <th class="text-left">
                  SFTP Password
                </th>
                 <th class="text-left" v-if="siteData.subdomain || siteData.status == 'inactive'">
                  DNS Propogation
                </th>
              </tr>
            </thead>
            <tbody>
            
              <tr>
                <td>  {{ siteData.wp_user }} </td>
                <td>  {{ siteData.wp_pass }} </td>
                <td>  {{ siteData.sftp_user }} </td>
                <td>  {{ siteData.sftp_pass }} </td>
                <td v-if="siteData.propogated && (siteData.subdomain || siteData.status == 'inactive')">  
                    <v-btn v-if="(siteData.subdomain || siteData.status == 'inactive')" color="primary" @click="changeUriDialog = true">Go Live!</v-btn>
                </td>
                 <td v-else-if="(siteData.subdomain || siteData.status == 'inactive') && !siteData.propogated">       
                          Not Propogated, unable to go live.
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
                        <v-btn          v-if="account_type !='PBN'"
 color="grey" @click="createStagingDialog = true">Create Staging</v-btn>

        </v-list-item-content>

            </v-card>

          </v-col>
        </v-row>


 <v-row>
        <v-col>
          <v-card class="mx-auto">
         <v-list-item-content>
    
        <v-list-item-title class="headline mb-1 ml-4">
          <v-row>
               <v-col class="overline mb-2 align-left">
               Environment
               <v-icon>
                 mdi-file-multiple
               </v-icon>
              </v-col>
            </v-row>
        </v-list-item-title>
      
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
             
                <th class="text-left">
                 PHP Version
                </th>
                <th class="text-left">
                  Server Location
                </th>
                <th class="text-left">
                  IP Address
                </th>
                <th class="text-left">
                  Site Status
                </th>
            
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>  {{ siteData.php_version }} </td>
                <td>  {{ siteData.country }} ({{ siteData.city }}) </td>
                <td>  {{ siteData.ip_address   }} </td>
                <td>  {{ siteData.status   }} </td>

            </tr>
            </tbody>
          </template>
        </v-simple-table>

        </v-list-item-content>
 
            </v-card>
          </v-col>
        </v-row>
     
          <v-row>
        <v-col>
          <v-card class="mx-auto">
         <v-list-item-content>
    
        <v-list-item-title class="headline mb-1 ml-4">
          <v-row>
               <v-col class="overline mb-2 align-left">
               Redirects
               <v-icon>
                 mdi-cached
               </v-icon>
              </v-col>
            </v-row>
        </v-list-item-title>
      
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
             
                <th class="text-left">
                 From
                </th>
                <th class="text-left">
                  To
                </th>
                <th class="text-left">
                  HTTPS
                </th>
              </tr>
            </thead>
            <tbody>
            
              <tr v-for="(redirect) in siteData.redirects" v-bind:key="redirect.id">

                <td>  {{ redirect.from }} </td>
                <td>  {{ redirect.to }} </td>
                <td>  {{ redirect.https }} </td>
            
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        </v-list-item-content>
        <div class="text-right" >
             <v-btn
                      v-if="account_type !='PBN'"

            class="mx-2 mb-2"
            fab
            dark
            color="indigo"
            align="right"
            @click="createRedirectDialog = true"
            >
            <v-icon dark>
                mdi-plus
            </v-icon>
            </v-btn>
            </div>
            </v-card>
          </v-col>
        </v-row>
  
      <v-row>
        <v-col>
          <v-card class="mx-auto">
         <v-list-item-content>
    
        <v-list-item-title class="headline mb-1 ml-4">
          <v-row>
               <v-col class="overline mb-2 align-left">
               Backups
               <v-icon>
                 mdi-file-multiple
               </v-icon>
              </v-col>
            </v-row>
        </v-list-item-title>
      
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
             
                <th class="text-left">
                 ID
                </th>
                <th class="text-left">
                  Date
                </th>
                <th class="text-left">
                  Filename
                </th>
                  <th class="text-left">
                  Actions
                </th>
                <th class="text-left">
                  
                </th>
            
              </tr>
            </thead>
            <tbody>
            
              <tr v-for="(backup) in siteData.backups" v-bind:key="backup.id">

                <td>  {{ backup.id }} </td>
                <td>  {{ backup.createdAt.split("T")[0] }}   {{ backup.createdAt.split("T")[1].split(".000Z")[0] }} </td>
                <td>  {{ backup.filename  }}  </td>
                <td>     <v-btn    v-if="account_type !='PBN'" color="primary" @click=" () => { restoreDialog = true; restoreBackupId = backup.id;} ">Restore</v-btn> </td>
                <td>  <v-btn   v-if="account_type !='PBN'" text color="primary" @click=" () => { deployStagingDialog = true; restoreBackupId = backup.id;} ">Deploy to Staging</v-btn> </td>

              </tr>
            </tbody>
          </template>
        </v-simple-table>

        </v-list-item-content>
        <div class="text-right" >
             <v-btn
                      v-if="account_type !='PBN'"

            class="mx-2 mb-2"
            fab
            dark
            color="indigo"
            align="right"
            @click="createBackup(domain)"
            >
            <v-icon dark>
                mdi-plus
            </v-icon>
            </v-btn>
            </div>
            </v-card>
          </v-col>
        </v-row>
     


   <v-row>
        <v-col>
          <v-card class="mx-auto">
         <v-list-item-content>
    
        <v-list-item-title class="headline mb-1 ml-4">
          <v-row>
               <v-col class="overline mb-2 align-left">
               Jobs
               <v-icon>
                 mdi-file-multiple
               </v-icon>
              </v-col>
            </v-row>
        </v-list-item-title>
      
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
             <th class="text-left">
                 Date/Time
                </th>
                <th class="text-left">
                 UUID
                </th>
                <th class="text-left">
                  Status
                </th>
                  <th class="text-left">
                  Action
                </th>
            
              </tr>
            </thead>
            <tbody>
            
              <tr v-for="(job) in siteData.jobs" v-bind:key="job.id">
                <td>  {{ job.createdAt }} </td>
                <td>  <a :href="'https://app.cubixd.com/job/'+job.uuid" > {{ job.uuid }} </a> </td>
                <td>  {{ job.message }}  </td>
                <td> {{ job.action }} </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        </v-list-item-content>
       
            </v-card>
          </v-col>
        </v-row>

       <v-dialog
      v-model="deployStagingDialog"
      persistent
      max-width="600px"
    >
 
      <v-card>
        <v-card-title>
          <span class="headline">Deploy staging site for {{ siteData.uri }} from backup.</span>
        </v-card-title>
        <v-card-text>
            Are you sure you would like to deploy this backup to a staging site?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deployStagingDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            text
            @click="deployStaging(domain,restoreBackupId)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   <v-dialog
      v-model="restoreDialog"
      persistent
      max-width="600px"
    >
 
      <v-card>
        <v-card-title>
          <span class="headline">Restore {{ siteData.uri }} from backup.</span>
        </v-card-title>
        <v-card-text>
         Restoring from this backup will overwrite your current live site and you will lose any changes that you have made after the backup was taken. Are you sure you would like to restore from this backup?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="restoreDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            text
            @click="restoreBackup(domain,restoreBackupId)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
       <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="600px"
    >
 
      <v-card>
        <v-card-title>
          <span class="headline">Delete {{ siteData.uri }}</span>
        </v-card-title>
        <v-card-text>
          Are you sure you would like to delete this website?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deleteDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            text
            @click="deleteWebsite(domain)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     
       <v-dialog
      v-model="createStagingDialog"
      persistent
      max-width="600px"
    >
 
      <v-card>
        <v-card-title>
          <span class="headline">Create Staging Environment</span>
        </v-card-title>
        <v-card-text>
          An exact clone of your live site will be created under the domain staging.{{ siteData.uri }}.cubixd.com
          <v-container>
            <v-row>
              <!-- This site will not be accessible by google bot or any other crawler. -->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="createStagingDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            text
            @click="deployStaging(siteData.uri)"
          >
            Create Staging
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

       <v-dialog
      v-model="changeUriDialog"
      persistent
      max-width="600px"
    >
 
      <v-card>
        <v-card-title>
          <span class="headline">Custom Domain Change</span>
        </v-card-title>
        <v-card-text>
          Make sure you have created the DNS A records ( @ and www ) pointing to {{ siteData.ip_address }} *
          <v-container>
            <v-row>
              Url will be changed from Cubixd subdomain: <b> {{ siteData.uri }} </b> to your custom domain: <b> {{ siteData.uri.split(".cubixd.com")[0] }} </b>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="changeUriDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            text
            @click="updateUri"
          >
            Initiate Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


      <v-dialog
        v-model="createRedirectDialog"
        persistent
        max-width="600px"
       >
 
      <v-card>
        <v-card-title>
          <span class="headline">Redirect</span>
        </v-card-title>
        <v-card-text>
          <v-container>

             <v-form
                  ref="createRedirectForm"
                  lazy-validation>
                    <v-text-field
                      v-model="from"
                      :rules="[() => !!from || 'This field is required']"
                      prepend-icon="mdi-arrow-down-box"
                      label="From"
                      placeholder="Enter only the subdomain ex. shop"
                      required
                    />
                    <v-text-field
                      v-model="to"
                      :rules="[() => !!to || 'This field is required']"
                      prepend-icon="mdi-arrow-right-bold-box"
                      label="To"
                      placeholder="Enter full domain excluding http/https"
                      required
                      
                    />
                    <v-checkbox
                        v-model="https"
                        label="HTTPS/SSL"
                       ></v-checkbox>
                  </v-form>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="createRedirectDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            text
            @click="createRedirect(from,to,https)"
          >
            Create Redirect
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <div class="text-center ma-2">
          <v-snackbar
          v-model="snackbar"
          :color="color"
          :top='true'
        >
    
          {{ snackbarMessage }}
        
       <template v-slot:action="{ attrs }">
          <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="$router.push('/job/'+job_uuid)"
        >
          VIEW
        </v-btn>
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
        </v-snackbar>
     </div>


      <BottomNavBar/>

</v-main>
</template>

<script>
import BottomNavBar from "@/components/BottomNavBar";
import jwt from 'jsonwebtoken'

export default {
   components: {
    BottomNavBar
  },
  data: () => ({
       snackbar: false,
       snackbarMessage: "",
       domain: '',
       siteData: {},
       changeUriDialog: false,
       createStagingDialog: false,
       deployStagingDialog: false,
       newUri: "",
       color: "black",
       loaded: false,
       deleteDialog: false,
       restoreDialog: false,
       restoreBackupId: 0,
       createRedirectDialog: false,
       from: "",
       to: "",
       https: false,
       job_uuid: "effe-fef",
   
  }),
  created () {
      var token = jwt.decode(this.$store.state.token)
       this.account_type = token.account_type
       
    this.domain = this.$route.params.domain
 
      this.$store.dispatch('getSiteDetails', { domain: this.domain })
        .then( response => {
           this.siteData = response.data
           this.loaded = true
          
        })
        .catch(err => {
             console.log(err)
        })
  },
  methods: {
    updateUri () {
       //SHOW overlay 

         this.snackbarMessage = "Initiated custom domain update."
         this.snackbar = true

         this.$store.dispatch('goLive', { domain: this.domain })
        .then( response => {
             this.snackbarMessage = response.data.msg 
             this.job_uuid = response.data.job_uuid
             this.snackbar = true
             this.changeUriDialog = false
        })
        .catch(err => {
                this.snackbarMessage = err.response.data
                this.snackbar = true
                this.changeUriDialog = false

        })
     
    },
    deployStaging(domain,backupId = null){
       
          //deploy from backup
            this.$store.dispatch('createStaging', { domain, backupId })
            .then( response => {
            this.snackbarMessage = response.data.msg 
                this.job_uuid = response.data.job_uuid
                this.snackbar = true
                this.deployStagingDialog = false
            })
            .catch(err => {
                    this.snackbarMessage = err.response.data
                    this.snackbar = true
                    this.deployStagingDialog = false

            })
        
    },
  
    createBackup(domain){
       this.$store.dispatch('createBackup', { domain })
        .then( response => {
             this.snackbarMessage = response.data.msg 
             this.job_uuid = response.data.job_uuid
             this.snackbar = true
        })
        .catch(err => {
                this.snackbarMessage = err.response.data
                this.snackbar = true
        })
    },
    restoreBackup(domain, backupId){
          this.$store.dispatch('restoreBackup', { domain, backupId })
              .then( response => {
            this.snackbarMessage = response.data.msg 
             this.job_uuid = response.data.job_uuid
                               this.snackbar = true
                  this.restoreDialog = false
              })
              .catch(err => {
                      this.snackbarMessage = err.response.data
                      this.snackbar = true
                      this.restoreDialog = false

              })
    },
    deleteWebsite(domain){
          this.$store.dispatch('deleteWebsite', { domain })
              .then( response => {
            this.snackbarMessage = response.data.msg 
             this.job_uuid = response.data.job_uuid
                               this.snackbar = true
                  this.deleteDialog = false
              })
              .catch(err => {
                      this.snackbarMessage = err.response.data
                      this.snackbar = true
                      this.deleteDialog = false

              })   
     },
     createRedirect(from,to,https){
       var domain = this.domain
       if (this.$refs.createRedirectForm.validate()) {
            this.$store.dispatch('createRedirect', { from,to,https,domain })
                    .then( response => {
                        this.snackbarMessage = response.data.msg 
                        this.job_uuid = response.data.job_uuid
                        this.snackbar = true
                        this.createRedirectDialog = false
                    })
                    .catch(err => {
                            this.snackbarMessage = err.response.data
                            this.snackbar = true
                            this.createRedirectDialog = false
                    })

        }else{
             this.snackbarMessage = "Kindly fill in all the required fields."
            this.snackbar = true
        }
    }
  }

}
</script>
